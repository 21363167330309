<template>
    <div>
                
                <!-- <b-card no-body class="overflow-hidden" style="width:540 px;">
                  <b-row no-gutters>
                    <b-col md="6">
                      <b-card-img  :src="url + data.idnana" alt="Image" class="rounded-0"></b-card-img>
                    </b-col>
                    <b-col md="6">
                      <b-card-body :title="data.primer_nombre
                       + ' '+ data.primer_apellido
                       +' '+ data.segundo_apellido">
                        <b-list-group class="mt-3">
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'Edad' }}</b>
                            <b-badge variant="info" pill>{{data.edad}}</b-badge>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'Años de experiencia'}}</b>
                            <b-badge variant="info" pill>{{data.anios_experiencia}}</b-badge>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'Nacionalidad'}}</b>
                            <b-badge variant="info" pill>{{data.idnacionalidad}}</b-badge>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'Estado civil'}}</b>
                            <b-badge variant="info" pill>{{data.estado_civil}}</b-badge>
                          </b-list-group-item>
                          <b-list-group-item class="d-flex justify-content-between align-items-center">
                            <b>{{ 'Hijos'}}</b>
                            <b-badge variant="info" pill>{{data.hijos}}</b-badge>
                          </b-list-group-item>
                        </b-list-group>

                      </b-card-body>
                    </b-col>
                    <hr>
                    <div class="row ml-2">
                      <h4>Atributos</h4>
                      <div v-for="(item, index) in data.atributos" :key="index" class="col-md-3 ">
                       <atributos :data="item.grupo" :id="index" :data2="item.atributos" />
                      </div>
                    </div>
                  </b-row>
                  <hr>
                  <b-button style="background-color: #00B290 ;" href="#" @click="abrirModal(item.idnana)" >Ver Nana</b-button>
               
  
                </b-card> -->
             
    </div>             
</template>

<script>
import Atributos from '@/components/Atributos.vue';

import { fdcService } from "@/api/dispatcher.service";

export default {
  components: { Atributos },
    props: [ 'data'],
    
    components: {
      Atributos,
    },
    data() {
        return {
          url: "https://admincn.fabricadecodigo.dev:8081/imagen/nana/"

        } 
    },
    created () {  
        window.console.log('CONTENIDO PROPS Perfil', this.data)     
        this.datosPerfil()    
      
      /* this.formato() */
    },
    methods: {
      cerrarModal() {
        this.$emit('cerrarModal')
      },
       async datosPerfil(){
            let resp = await fdcService.execute("nanaPerfil",[this.data],{},{});
            window.console.log("respuesta perfil nana", resp);
            if(resp.resultado == 'ok'){
                this.dataNana = resp.nana
               
            }
             
        },
      /* formato(){
        this.data.primer_nombre = this.data.primer_nombre.replace(/(^|\s)\S/g, l => l.toUpperCase()),
        this.data.primer_apellido = this.data.primer_apellido.replace(/(^|\s)\S/g, l => l.toUpperCase()),
        this.data.segundo_apellido = this.data.segundo_apellido.replace(/(^|\s)\S/g, l => l.toUpperCase()),
        this.data.idnacionalidad = this.data.idnacionalidad.replace(/(^|\s)\S/g, l => l.toUpperCase()),
        this.data.hijos = this.data.hijos.replace(/(^|\s)\S/g, l => l.toUpperCase()),
        this.data.atributos.forEach(element => {
          element.grupo = element.grupo.replace(/(^|\s)\S/g, l => l.toUpperCase())
         
        });
      } */
        
    }           
};
</script>
<style scoped>
.card.overflow-hidden {
  border: none !important;
}
.badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: rgb(3, 3, 3);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    background-color: #ffffff;
}
.rounded-0{
  width: 320px !important;
  height: 240px !important;
}

</style>
